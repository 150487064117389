import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import {graphql, withPrefix } from 'gatsby'
import SEO from '../../components/seo'
import classNames from 'classnames'
import styles from './styles.module.scss'
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import { getBodySections, getSectionHeading, getSectionItems } from '../../utils/helper'
import Image from "../../components/Image";

const CoursePage = ({ data }) => {
  const {coursePage: page} = data;
  let bodySections = getBodySections(page.body);
  
  const crumbs = [
    {pathname: "/", crumbLabel: "Home"},
    {pathname: "/online-courses", crumbLabel: "Courses"},
    {pathname: withPrefix(page.categoryPages[0].categoryPagePath), crumbLabel: page.categoryPages[0].categoryPageName},
    {pathname: withPrefix(page.path), crumbLabel: page.name}
  ];
  
  return (
    <Layout>
      <SEO title={page.metaTags.title} description={page.metaTags.description} />
      
      <CustomBreadcrumbs crumbs={crumbs} />
      
      <h1>{page.title}</h1>
  
      { !!page.image && <Image imgName={page.image} /> }

      <section className={styles.intro} dangerouslySetInnerHTML={{__html: page.intro}}/>
      
      {
        bodySections && Object.keys(bodySections) ? Object.keys(bodySections).map((key, index) => {
          return bodySections[key] && bodySections[key].length > 0 ? (
            <section className={classNames(styles[key], styles.bodySection)} key={index}>
              {key === 'faq' ?
                  <>
                    {getSectionHeading(bodySections[key])}
                    {getSectionItems(bodySections[key]).map((item, index) => (
                      <div className={styles.item} key={index}>{item}</div>
                    ))}
                  </>
                :
                  bodySections[key]
              }
            </section>
          ) : ''
        }) : <section className={styles.bodySection} dangerouslySetInnerHTML={{__html: page.body}} />
      }
    </Layout>
  )
};

CoursePage.propTypes = {
  data: PropTypes.shape({
    degreePage: PropTypes.object
  })
};

export default CoursePage

export const pageQuery = graphql`
  query($id: String!) {
    coursePage(nid: {eq: $id}) {
      metaTags {
        title
        description
      }
      sections {
        section
      }
      categoryPages {
        categoryPage
        categoryPageName
        categoryPagePath
      }
      name
      title
      intro
      body
      path
      image
    }
  }
`;
